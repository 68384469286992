import { useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

function App() {
  const [umsatz, setUmsatz] = useState('');
  const [gewinn, setGewinn] = useState('');
  const [output, setOutput] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = await fetch(
      'https://consiliumhafner.ch/wp-content/themes/consilium-hafner-theme/unternehmenswertrechner/api/?json=data'
    ).then((resp) => resp.json());

    let array = data.map((item) => {
      if (item.data && item.data.length === 4) {
        return {
          branch: item.branch,
          data: round(
            [
              item.data[0] * umsatz,
              item.data[1] * umsatz,
              item.data[2] * gewinn,
              item.data[3] * gewinn,
            ].reduce(
              (accumulator, currentValue) => accumulator + currentValue
            ) / item.data.length,
            -5
          ),
        };
      }

      return { branch: item.branch, data: false };
    });

    setOutput(array);
    setLoading(false);
  };

  const round = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces);

    return parseFloat(
      (Math.round(number * factorOfTen) / factorOfTen).toFixed(0)
    );
  };

  return (
    <div className='rechnerWrapper'>
      <form className='rechner' onSubmit={onSubmit}>
        <div className='rechnerFields row'>
          <label htmlFor='umsatzId' className='col-sm-6'>
            <span className='labelText'>Umsatz*</span>
            <NumberFormat
              value={umsatz}
              id='umsatzId'
              className='inputUmsatz'
              placeholder='Betrag eingeben'
              displayType={'input'}
              thousandSeparator="'"
              allowNegative={false}
              isNumericString={true}
              onValueChange={(event) => setUmsatz(event.value)}
            />
          </label>
          <label htmlFor='gewinnId' className='col-sm-6'>
            <span className='labelText'>Gewinn*</span>
            <NumberFormat
              value={gewinn}
              id='gewinnId'
              className='inputGewinn'
              placeholder='Betrag eingeben'
              displayType={'input'}
              thousandSeparator="'"
              allowNegative={false}
              isNumericString={true}
              onValueChange={(event) => setGewinn(event.value)}
            />
          </label>
        </div>
        <Button
          variant='consiliumhafner'
          disabled={!umsatz || !gewinn}
          type='submit'
          block
        >
          Rechnen
        </Button>
      </form>
      {!loading && output ? (
        <div className='resultWrapper'>
          <Table className='result' striped={true}>
            <thead>
              <tr>
                <th>Branche</th>
                <th>Unternehmenswert</th>
              </tr>
            </thead>
            <tbody>
              {output.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{item.branch}</td>
                    <td>
                      {item.data ? (
                        <NumberFormat
                          value={item.data}
                          displayType={'text'}
                          thousandSeparator="'"
                        />
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : loading ? (
        <div className='spinnerWrapper'>
          <Spinner animation='border' variant='consiliumhafner' />
        </div>
      ) : null}
    </div>
  );
}

export default App;
