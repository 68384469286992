import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import './assets/styles/style.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('unternehmenswertrechner')
);
